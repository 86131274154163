import React from "react"
import { navigate } from '@reach/router'
import { StaticQuery } from "gatsby"
import Img from "gatsby-image"

  const TeamMembersTeaser = (props) => (
    <StaticQuery
      query={graphql`
        query {
          katrin: contentfulTcTeam(contentful_id: {eq: "29kev8qsNxblrsQacWlhI9"}) {
            fullName
            position
            profilePicture{
              fluid(maxWidth: 250, quality: 81) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }

          daniela: contentfulTcTeam(contentful_id: {eq: "2GuhBmUZcK5ZQ6tNrC3Dy"}) {
            fullName
            position
            profilePicture{
              fluid(maxWidth: 250, quality: 81) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }

          julietta: contentfulTcTeam(contentful_id: {eq: "1Has6PgZKBWaTUHw5yK2iJ"}) {
            fullName
            position
            profilePicture{
              fluid(maxWidth: 250, quality: 81) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      `
      }

    render = {data => (
      <section class="section" div="values">
        <div class="container is-fluid wrapper standard-margin">

      <div class="content has-text-centered">
        <br/>
        <h1 class="title has-text-primary has-text-weight-light">Unser Team</h1>
        <br/>
        <br/>

        <div class="columns">
          <div class="column">
            <Img style={{borderRadius: "50%", maxWidth: "250px", display: "block", marginLeft: "auto", marginRight: "auto"}} fluid={data.katrin.profilePicture.fluid} alt=''/>
            <h1 class="title is-4 has-text-primary has-text-weight-light">{data.katrin.fullName}</h1>
            <h2 class="subtitle is-5 has-text-black has-text-weight-normal">{data.katrin.position}</h2>
            <br/>
          </div>

          <div class="column">
            <Img style={{borderRadius: "50%", maxWidth: "250px", display: "block", marginLeft: "auto", marginRight: "auto"}} fluid={data.daniela.profilePicture.fluid} alt=''/>
            <h1 class="title is-4 has-text-primary has-text-weight-light">{data.daniela.fullName}</h1>
            <h2 class="subtitle is-5 has-text-black has-text-weight-normal">{data.daniela.position}</h2>
            <br/>
          </div>

          <div class="column">
            <Img style={{borderRadius: "50%", maxWidth: "250px", display: "block", marginLeft: "auto", marginRight: "auto"}} fluid={data.julietta.profilePicture.fluid} alt=''/>
            <h1 class="title is-4 has-text-primary has-text-weight-light">{data.julietta.fullName}</h1>
            <h2 class="subtitle is-5 has-text-black has-text-weight-normal">{data.julietta.position}</h2>
            <br/>
          </div>
      </div>

      <div class="level pb-1">

            {props.convert && (

        <div class="level-item has-text-centered">
        <div>
        <button type="submit" href="#bsd-home" class="birdseed-link button is-primary is-medium">
        Expertinnen kontaktieren
        </button>
        </div>
        </div>

                    )}

        <div class="level-item has-text-centered">

        <form
        onSubmit={ event => {
          event.preventDefault()
          navigate("/ueber-uns")
        }}
        >
        <button type="submit" class="button is-primary is-medium is-outlined">Mehr erfahren</button>
        </form>
        </div>
    </div>





      </div>

      </div>
      </section>

    )}
    />
    )


export default TeamMembersTeaser
