import React from "react"

class TestimonialsTeaser extends React.Component {

  render () {

    const { convert } = this.props

    return (

      <section class="section no-padding" div="values">
        <div class="container is-fluid wrapper standard-margin">

      <div class="content has-text-centered" div="prinzipien">
        <br/>
        <h1 class="title has-text-primary has-text-weight-light">Was unsere Kunden sagen</h1>
        <br/>
        <br/>

        <div class="columns">

        <div class="column">
          <h3 style={{textAlign: "center"}} class="has-text-weight-bold has-text-primary">
            <i class="fa fa-star is-medium"></i>
            <i class="fa fa-star"></i>
            <i class="fa fa-star"></i>
            <i class="fa fa-star"></i>
            <i class="fa fa-star"></i>
            <br/>
          </h3>

          <h2 class="subtitle is-5  has-text-grey has-text-weight-light">
          Wir sind wirklich immer sehr zufrieden mit dem tollen Reisebüro.  Alle sind wirklich unfassbar freundlich und ehrlich . Frau Weber ist unsere Ansprechpartnerin und Sie ist wundervoll.  Danke für alles. Immer wieder buchen wir gerne unsere Reisen bei Ihnen. Alles klappt reibungslos.
          <br/>
          </h2>
          <h1 class="title is-5 has-text-primary has-text-weight-normal">Susa T. - Kundin</h1>
        </div>

          <div class="column">
            <h3 style={{textAlign: "center"}} class="has-text-weight-bold has-text-primary">
              <i class="fa fa-star is-medium"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <br/>
            </h3>

            <h2 class="subtitle is-5  has-text-grey has-text-weight-light">
            Nette Beratung. Immer gute Ideen. Klein, aber fein <span role="img" aria-label="Zwinker">😉</span> Man muss nur an die Mittagspause von 13-15.00 Uhr denken.
            <br/>
            </h2>
            <h1 class="title is-5 has-text-primary has-text-weight-normal">Martina J. - Local Guide</h1>
          </div>

          <div class="column">
            <h3 style={{textAlign: "center"}} class="has-text-weight-bold has-text-primary">
              <i class="fa fa-star is-medium"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <br/>
            </h3>

            <h2 class="subtitle is-5  has-text-grey has-text-weight-light">
            [...] Dieses  Reisebüro ist: Das Reisebüro unseres Vertrauens. Dort wird nicht "nur" verkauft, sie sorgen mit großem Arrangement dafür das es die schönste Zeit des Jahres wird.
            <br/>
            </h2>
            <h1 class="title is-5 has-text-primary has-text-weight-normal">Stefan G. - Kunde</h1>
          </div>
      </div>

      <div class="level pb-1">

        {convert && (
          <div class="level-item has-text-centered">
          <div>
          <button type="submit" href="#bsd-home" class="birdseed-link button is-primary is-medium">
          Expertinnen kontaktieren
          </button>
          </div>
          </div>
        )}

        <div class="level-item has-text-centered">
        <a href="https://www.google.com/search?q=travel+connection+langenfeld&oq=travel+connection+langenfeld&ie=UTF-8#lrd=0x47bf2d59fc5f9a95:0xf8cce7e64bacef02,1,,,">
        <button type="submit" class="button is-primary is-medium is-outlined">Mehr erfahren</button>
        </a>
        </div>

      </div>
      </div>
      </div>
      </section>

    )
  }
}

export default TestimonialsTeaser
