import React from "react"
import Layout from "../components/Layout/layout"
import Maps from "../components/About/maps"
import TeamMembersTeaser from "../components/Teaser/wir"
import TestimonialsTeaser from "../components/Teaser/testimonials"
import ServiceTeaser from "../components/Teaser/service"

const ThirdPage = () => (
  <Layout
  heroURL="https://images.ctfassets.net/wm9p0wxrtkh3/71GxIU2jgIa4KRRjacAo78/3aae67ffc650012f242b69062870386c/buero_header.jpg"
  slug = 'fruehbucher'>

  <div class="notification is-white" style={{opacity: "85%", position: "absolute", right: "1rem", top:"5rem", left: "1rem"}}>
  <section class="section no-padding" div="funnel">
  <h1 class="subtitle has-text-weight-bold has-text-primary">Reisebüro Travel Connection - Hier bucht Langenfeld</h1>
  <h2 class="has-text-weight-normal has-text-grey">
  Ob <b>Türkei, Griechenland, Oman, Thailand oder Karibik</b> - Wir waren dort und haben <b>persönliche Empfehlungen für Sie</b>.</h2>
  <h2 class="has-text-weight-normal has-text-grey">  Wir bieten: </h2>

  <div class="content has-text-weight-bold has-text-primary">
  <ul>
    <li>Unverbindliche & kostenlose Beratung</li>
    <li>Persönliche Betreuung</li>
    <li>Erfahrene Reiseexpertinnen</li>

  </ul>
  </div>

  <h2 class="has-text-weight-normal has-text-grey">
  Die <b>Frühbucher Angebote 2020</b> sind da. Jetzt anrufen oder Termin vereinbaren!
  </h2>
    <br/>

  <button style={{width: "100%"}} type="submit" href="#bsd-home" class="birdseed-link button has-text-weight-normal is-primary is-medium">
  Expertinnen kontaktieren
  </button>
  </section>
  </div>

  <TestimonialsTeaser convert="true"/>
  <TeamMembersTeaser convert="true"/>
  <ServiceTeaser convert="true"/>
  <Maps/>
  </Layout>
)

export default ThirdPage
