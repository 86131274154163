import React from "react"
import { navigate } from 'gatsby';

class ServiceTeaser extends React.Component {

  render () {

    const { convert } = this.props

    return (

      <section class="section no-padding" div="values">
        <div class="container is-fluid wrapper standard-margin">

      <div class="content has-text-centered" div="prinzipien">
        <br/>
        <h1 class="title has-text-primary has-text-weight-light">Unsere Leistungen</h1>
        <br/>
        <br/>

        <div class="columns">
          <div class="column">
            <span class="icon has-text-primary is-large fa-5x">
              <i class="fas fa-comments"></i>
            </span>
            <h1 class="title is-4 has-text-primary has-text-weight-light">Persönliche Planung</h1>
            <br/>
          </div>

          <div class="column">
            <span class="icon has-text-primary is-large fa-5x">
              <i class="fas fa-globe"></i>
            </span>
            <h1 class="title is-4 has-text-primary has-text-weight-light">Individuelle Erlebnisse</h1>
            <br/>
          </div>

          <div class="column">
            <span class="icon has-text-primary is-large fa-5x">
              <i class="fas fa-spa"></i>
            </span>
            <h1 class="title is-4 has-text-primary has-text-weight-light">Wohlbefinden & Komfort</h1>
            <br/>
          </div>
      </div>

      <div class="level pb-1">

            {convert && (

        <div class="level-item has-text-centered">
        <div>
        <button type="submit" href="#bsd-home" class="birdseed-link button is-primary is-medium">
        Expertinnen kontaktieren
        </button>
        </div>
        </div>

                    )}

        <div class="level-item has-text-centered">

        <form
        onSubmit={ event => {
          event.preventDefault()
          navigate("/service")
        }}
        >
        <button type="submit" class="button is-primary is-medium is-outlined">Mehr erfahren</button>
        </form>
        </div>
    </div>


      </div>

      </div>
      </section>

    )
  }
}

export default ServiceTeaser
